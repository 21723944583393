
import Head from "next/head";
import Link from "next/link";
import router, { useRouter } from "next/router";
import React, { useEffect, useState, useRef } from "react";
import { useForm } from "react-hook-form";
import api from '../../../api/Apis'
import Product from "../../Components/Cart/MiniCart/ProductComponext";
import NumberFormat from 'react-number-format';
import cookieCutter from 'cookie-cutter'
import MinCartStyle from "./MinCartStyle";
import _ from "lodash";
import ViewCartPopup from "@Components/ViewCartPopup";
import { SubTotalSection } from "./viewCart";
import NumberFormatComp from "@Components/Common/NumberFormatComp";
import { DeleteOutline } from "@material-ui/icons";
import { _freeKitQuantityCheck } from "@Components/Common/CommonMethods";

export default function Minicart({ cartdata, setActiveStep, activeStep, setCartData, smartShipCheck, getMiniCartProduct, setInnerLoader, promoterPackAvailability, isLogin, setdiscount_amount, discount_amount1,
  coupon, setcoupon, amountCalculations, setAmountCalculations, ...props }) {
  const wrapperRef = useRef(null);
  const [setdeletemessage, setDeleteMessage] = useState();
  const [logintoken, setToken] = useState();
  const [alreadypaid, setAlreadyPaid] = useState();
  const router = useRouter();

  //delete product from minicart
  const deleteproduct = async (e, cartId, autoship) => {
    const formDataDelete = { product_id: e, variant_id: e?.target?.dataset?.value, id: cartId, is_autoship: autoship, token: logintoken };
    await api.deleteProductFromAddToCart(formDataDelete).then(res => {
      setDeleteMessage(res?.data)
      if (res?.data?.code === 1) {
        localStorage.removeItem("PromoterPack")
        getMiniCartProduct("delete", logintoken);
        localStorage.removeItem('coupon_name');
        setcoupon()
        setAmountCalculations();
        setdiscount_amount(0);
        if (formDataDelete?.product_id === "6") {
          localStorage.removeItem("ancestryKey")
        }
      }
    })
  };
  useEffect(() => {

    const token = localStorage.getItem('Token') ? localStorage.getItem('Token') : null

    // const dna_product_paid = localStorage.getItem('dna_product_paid') ? localStorage.getItem('dna_product_paid') : null;

    // setAlreadyPaid(dna_product_paid)

    setToken(token)
    getMiniCartProduct("onLoadData", token);
    document.addEventListener("click", handleClickOutside, false);

    return () => {
      document.removeEventListener("click", handleClickOutside, false);
    };

  }, [props?.showminicart]);

  // cart total
  let total = 0;
  cartdata?.products?.forEach((cart) => {
    // if (cartdata?.is_autoship_user === 'True') {
    //   total = +(cart.product.autoship_cost_price * cart.quantity) + total;
    // }
    // else
    if (cart.is_autoship === "True") {
      total = +(cart.product.autoship_cost_price * cart.quantity) + total;
    }
    else {
      total = +(cart.product.cost_price * cart.quantity) + total;
    }
  });

  //onclick checkout validate user login or not.
  const Validatelogin = (e) => {
    setTimeout(() => props?.setshowminicart(false), 2000);
    if (router?.query?.page === undefined) {
      router.push('/us/checkout/addresslist')
    }
    else {
      router.push(`/${router.query.page}/checkout/addresslist`)

    }
  }

  //update cart data >>>>>>>>>>>>>>
  const UpdateCartData = async (datas, section) => {
    setInnerLoader(true)
    const update_data = {
      token: logintoken,
      datas: datas
    }
    await api.updateCart(update_data).then(res => {
      if (res.data.code === 1) {
        getMiniCartProduct(section, logintoken);
        setInnerLoader(false)
      }
    });
  }

  //add qty of product in mini cart
  const Add = async (e, cartId, is_autoship) => {
    if (+(e.target.value) >= 0 && +(e.target.value) < +(e?.target?.dataset?.value)) {
      const QUANTITY = _freeKitQuantityCheck(+(e.target.value), cartdata?.products, cartId);
      const data_update = e?.target?.name.split(',')
      const datas = {
        product_id: +(data_update[0]),
        variant_id: +(data_update[1]),
        quantity: QUANTITY,
        id: cartId,
        cookie_id: cookieCutter.get('sessionkey'),
        is_autoship: is_autoship === "True" ? true : false
      }
      if (QUANTITY)
        UpdateCartData(datas, "Add");
      setcoupon()
      setAmountCalculations();
      setdiscount_amount(0);

    }
  }
  //subtract qty of product from minicart.

  const Sub = (e, cartId, is_autoship) => {
    const data_update = e?.target?.name.split(',')
    const datas = {
      product_id: +(data_update[0]),
      variant_id: +(data_update[1]),
      id: cartId,
      quantity: +(e.target.value) > 0 ? (+(e.target.value) - 1) : +(e.target.value),
      cookie_id: cookieCutter.get('sessionkey'),
      is_autoship: is_autoship
    }
    UpdateCartData(datas, "Sub");
    setcoupon()
    setAmountCalculations();
    setdiscount_amount(0);
  }
  //close minicart
  const closeminicart = () => {
    props.setshowminicart(false)
  }

  //click outside of minicart
  const handleClickOutside = event => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      props?.setshowminicart(false)
    }
  };

  function handleReview() {
    setActiveStep((prevActiveStep) => {
      return prevActiveStep + 1
    })
    props.setshowminicart(false)
  }

  function openMiniCart() {
    // document.body.classList.add("openminicart")
    props?.setshowminicart(true);
  }

  const hiddenHeaderUrls = ["/smartshipproducts/"];

  const isHiddenHeaderUrl = hiddenHeaderUrls?.includes(router?.asPath);

  const Code_complex_product = _.filter(cartdata?.products, (row) => row?.product?.sku === "SN-DNA-KIT");


  //   const dna_product_paid = localStorage.getItem('dna_product_paid') ? localStorage.getItem('dna_product_paid') : null;

  // let sub_total;
  //   if(dna_product_paid && Code_complex_product.length>0 ){

  //     const Already_paid_amount=localStorage.getItem('dna_product_paid');

  //     if(Already_paid_amount){
  //      sub_total=parseInt(total)+parseInt(Already_paid_amount);

  //     }else{
  //      sub_total=total;
  //     }
  //   }else{
  //     sub_total=total;
  //   }


  let dna_product_paid;
  let sub_total;
  if (cartdata?.already_paid && Code_complex_product.length > 0) {
    dna_product_paid = parseInt(Code_complex_product?.[0]?.product?.real_amount) - parseInt(Code_complex_product?.[0]?.product?.cost_price);
    sub_total = total + dna_product_paid;
  } else {
    dna_product_paid = "";
    sub_total = total;

  }


  return (<>
    <Head>
      <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css" />
    </Head>

    <MinCartStyle>
      {props?.showminicart !== false ?
        <div className="mini-cart-sec" ref={wrapperRef}>
          <div className="container">
            <div className="cart-heading">
              <h1 className="title" > Shopping Cart  </h1>
              <button onClick={closeminicart}>X</button>
            </div>
            <div className="Cart_product">
              {cartdata?.message && <h3 className="title">{cartdata.message}</h3>}

              <div className="mini-cart-sc">
                {
                  cartdata?.products?.map((Cart, index) => {
                    return (
                      <div key={Cart?.id}>
                        <Product
                          key={index + 1}
                          logintoken={logintoken}
                          smartShipCheck={smartShipCheck}
                          minCartAutoshipCheck={props?.minCartAutoshipCheck}
                          setCartData={setCartData}
                          Cart={Cart}
                          cartdata={cartdata}
                          subscribeAlready={props?.subscribeAlready}
                          getMiniCartProduct={getMiniCartProduct}
                          productData={cartdata?.products}
                          deleteproduct={deleteproduct}
                          index={index} Add={Add} Sub={Sub}
                          setcoupon={setcoupon}
                          setAmountCalculations={setAmountCalculations}
                          setdiscount_amount={setdiscount_amount}
                        />
                      </div>
                    )

                  })
                }
              </div>
              {cartdata?.products && cartdata?.message !== 'Cart is empty' &&
                <div className="checkout-btn">
                  <div className="row">
                    <div className="col-md-12">
                      <ul>
                        <li><strong>Sub-total:</strong>
                          <NumberFormatComp value={sub_total} />
                        </li>
                        {cartdata?.already_paid && Code_complex_product.length > 0 && <li><strong>Already Paid:</strong>
                          <NumberFormatComp value={dna_product_paid} />
                        </li>
                        }
                        <li>
                          <strong>Total:</strong>
                          <NumberFormatComp value={total} />
                        </li>
                      </ul>
                    </div>
                    <>
                      <div className="d-flex btn-flex" >
                        <button type="button" className="btn btn-primary" onClick={() => props?.setshowminicart(false)} >Continue Shopping</button>
                        <button type="button" className="btn btn-primary" onClick={(e) => { Validatelogin(e) }} >Checkout</button>
                      </div>
                    </>
                  </div>
                </div>
              }
            </div>

          </div>
        </div>
        :
        <div>
          {!isHiddenHeaderUrl &&
            <div className="mini-root-div" onClick={() => { props?.setshowminicart(true) }}>
              <div onClick={() => openMiniCart()} className="fa fa-shopping-cart">
                {cartdata?.products?.length > 0 &&
                  <span>{(props?.counts && !isNaN(props?.counts)) && props?.counts}</span>
                }
              </div>
            </div>
          }
        </div>
      }

    </MinCartStyle>

  </>);
}