import Head from "next/head";
import _ from 'lodash';
import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import api from '../../../api/Apis'
import Product from "../../Components/Cart/ProductComponext";
import cookieCutter from 'cookie-cutter'
import ViewCartStyle, { RelatedProductStyle } from "./ViewCartStyle";
import SvgAnimationBackground from "@Components/Common/SvgAnimationBackground";
import NumberFormatComp from "@Components/Common/NumberFormatComp";
import { _freeKitQuantityCheck, _getAllProduct, _getCartProducts, addToCartMethod } from "@Components/Common/CommonMethods";
import { count } from "d3";
import Cookie from "js-cookie";
import Router from "next/router";
import RelatedProducts2 from "@Components/Common/RelatedProducts/RelatedProducts2";
import RelatedProduct from "../../../Components/Common/RelatedProducts";

export const SubTotalSection = ({ message, total, Validatelogin, cartdata }) => {
  const [customData, setCustomData] = useState()

  useEffect(() => {
    const customPackData = localStorage.getItem('packProduct')
    setCustomData(customPackData)

  }, []);

  const Code_complex_product = _.filter(cartdata?.products, (row) => row?.product?.sku === "SN-DNA-KIT");

  let dna_product_paid;
  let sub_total;
  if (cartdata?.already_paid && Code_complex_product.length > 0) {
    dna_product_paid = parseInt(Code_complex_product?.[0]?.product?.real_amount) - parseInt(Code_complex_product?.[0]?.product?.cost_price);
    sub_total = total + dna_product_paid;
  } else {
    dna_product_paid = "";
    sub_total = total;

  }


  return (
    (message !== 'Cart is empty' && cartdata?.products?.length > 0) &&
    <>
      <tr className="checkout-btn">
        <td colSpan={6} >
          <ul>
            <li><strong>Sub-total:</strong>
              <NumberFormatComp value={sub_total} />
            </li>
            {cartdata?.already_paid && Code_complex_product.length > 0 && <li><strong>Already Paid:</strong>
              <NumberFormatComp value={dna_product_paid} />
            </li>
            }
            <li>
              <strong>Total:</strong>
              <NumberFormatComp value={total} />
            </li>
          </ul>
        </td>
      </tr>
      <tr>
        <td colSpan={6} >
          <div className="checkout_button">
            <button type="button" className="btn btn-primary" onClick={(e) => { Validatelogin(e) }} >Checkout</button>
          </div>
        </td>
      </tr>
    </>
  )
}

export default function Cart({ showloader, setshowminicart, smartShipCheck, smartShipOnCart, releaseResult,
  setshowloader, isLogin, setInnerLoader, setSmartShipCheck, cartdata, setCartData, setdiscount_amount, discount_amount1, setAmountCalculations, setcoupon, ...props }) {
  // const [cartdata, setCartData] = useState();

  const [logintoken, setToken] = useState();
  const [costtype, setcosttype] = useState([])
  const [is_autoship, setis_autoship] = useState();
  const route = useRouter();
  const [selectedProductFreq, setSelectedProductFreq] = useState({
    product_id: null, shipping_amount: null, quantity: null,
    shipping_module_id: null, variant_id: null, shipping_frequency: null,
    is_autoship: false, tax_amount: null, amount: null,
  });
  const [frequentlyProduct, setFrequentlyProduct] = useState([]);
  const [autoshipCheck, setAutoshipCheck] = useState(true);
  const [incQty, setDecQty] = useState(1);
  const [customProductsData, setCustomProductsData] = useState([])
  const [errrormsg, setErrorMsg] = useState()
  const [cartNotify, setCartNotify] = useState('');
  const [dnaReportData, setDnaReportData] = useState();
  const [product, setProduct] = useState({
    id: 2,
    name: "CODE Complex DNA Personalized Nutrition - Capsules (30-day supply)",
  })


  const PRODUCTS_SKU = ["SN-CC-CAPS-30-NEW", "SN-CC-CAPS-30", "SN-CC-PWDR-30-NEW", "SN-CC-PWDR-30",
    "SN-CC-CAPS-30-NEW_Subscription", "SN-CC-CAPS-30_Subscription", "SN-CC-PWDR-30-NEW_Subscription", "SN-CC-PWDR-30_Subscription"]
  let REJECT_CUSTOM_PRODUCT = _.filter(frequentlyProduct, function (row) { return ["SN-CC-PWDR-30", "SN-CC-CAPS-30"]?.includes(row?.sku) });
  const FILTER_PACKS = REJECT_CUSTOM_PRODUCT?.map((row) => row?.sku) || [];
  const matchingItemIDS = PRODUCTS_SKU?.filter(sku => FILTER_PACKS?.includes(sku));

  const [selectedProduct, setSelectedProduct] = useState(matchingItemIDS?.[0] === "SN-CC-CAPS-30" ? 'SN-CC-CAPS-30_Subscription' : "SN-CC-CAPS-30-NEW_Subscription");

  //delete product from cart
  const deleteproduct = async (productId, variantId, cartId, is_autoship) => {
    const PARENT_REFFERAL = Cookie.get('refferal-code')?.length > 0 ? Cookie.get('refferal-code') : null;

    // setshowloader(true);
    // setInnerLoader(true)
    const formDataDelete = { product_id: productId, variant_id: variantId, id: cartId, is_autoship: is_autoship, token: logintoken };
    await api.deleteProductFromAddToCart(formDataDelete).then(res => {
      if (res?.data?.code === 1) {
        localStorage.removeItem('coupon_name');
        setdiscount_amount(0);
        api.getAllCartProduct({ refferalCode: PARENT_REFFERAL, logintoken: logintoken }).then(res => {
          const PRODUCTS = _getCartProducts(res?.data)
          setCartData(PRODUCTS);
          GetCounts(PRODUCTS?.products?.length)
          _onLoadSetCostType(PRODUCTS?.products);
        })
      }

    })
  }

  useEffect(() => {
    const DATA = localStorage.getItem("profileData") && JSON.parse(localStorage.getItem("profileData"))?.user_data?.userdetails?.[0]?.dna_report_release
    setDnaReportData(DATA);
  }, [route?.asPath])

  useEffect(() => {
    const PARENT_REFFERAL = Cookie.get('refferal-code')?.length > 0 ? Cookie.get('refferal-code') : null;
    api.getAllCartProduct({ refferalCode: PARENT_REFFERAL, logintoken: logintoken }).then(res => {
      const PRODUCTS = _getCartProducts(res?.data)
      setCartData(PRODUCTS);
      GetCounts(PRODUCTS?.products?.length)
      _onLoadSetCostType(PRODUCTS?.products);
    })
  }, [])


  function _onLoadSetCostType(data) {
    const cosTypeData = [];
    _.forEach(data, (row) => {
      if (row?.is_autoship === "True") {
        setSmartShipCheck(true);
      }
      cosTypeData.push({ id: +row?.product?.id, orderId: row?.id, autoship: row?.is_autoship === "True" ? "AutoShip" : "Normal" });
    })
    setcosttype(cosTypeData)
  }
  function GetCounts(count) {
    const dataSss = localStorage.getItem('packProduct') ? JSON.parse(localStorage.getItem('packProduct')) : null
    let couuntValue = (dataSss !== null) ? count + 1 : count
    props?.setcounts(couuntValue)
  }

  //for qty counts
  let QTYCOUNTS = cartdata?.products?.reduce(
    (prevValue, currentValue) => prevValue + (+currentValue?.quantity), 0);

  useEffect(() => {
    const token = localStorage.getItem('Token') ? localStorage.getItem('Token') : null
    // setshowloader(true);
    setInnerLoader(true)
    setToken(token)
    if (_.keys(cartdata)?.length > 0) {
      setCartData(cartdata);
      setis_autoship(cartdata?.is_autoship_user);
      GetCounts(QTYCOUNTS)
      setInnerLoader(false)
      _onLoadSetCostType(cartdata?.products);
    } else {
      GetCounts(0);
      setInnerLoader(false)
    }

  }, [cartdata]);

  useEffect(() => {
    Promise.resolve(_getAllProduct(true)).then((res) => {
      if (res?.length > 0) {
        const VALUES = _.map(_.reverse(res), (row) => ({
          id: +row?.id,
          autoship_cost_price: +row?.autoship_cost_price,
          cost_price: +row?.cost_price,
          image: (row?.product_images?.length > 0) ? row?.product_images[0]?.image : "/media/products/2022/11/30/Screenshot_from_2022-11-18_13-00-44.png",
          name: row?.name,
          quantity: +row?.quantity,
          sku: row?.sku,
          tax_code: row?.tax_code,
          weight: row?.weight,
          real_amount: row?.real_amount
        }))
        setFrequentlyProduct(VALUES);
        // setQuantity(_.map(res, (row) => ({ id: +row?.id, qty: 1, maxQty: +row?.quantity })));
        // setFrequencyDate(_.map(res, (row) => ({ id: +row?.id, days: "30" })));
      }
    });
  }, [])

  // cart total
  let total = 0;
  cartdata?.products?.forEach((cart) => {
    // if (cartdata?.is_autoship_user === 'True') {
    //   total = (+cart.product.autoship_cost_price * +cart.quantity) + total;
    // }
    // else 
    if (cart.is_autoship === "True") {
      total = +(cart.product.autoship_cost_price * cart.quantity) + total;
    }
    else {
      total = +(cart.product.cost_price * cart.quantity) + total;
    }
  });

  //update cart data >>>>>>>>>>>>>>
  const UpdateCartData = async (datas, section) => {
    setInnerLoader(true)
    const update_data = {
      token: logintoken,
      datas: datas
    }
    await api.updateCart(update_data).then(res => {
      if (res.data.code === 1) {
        props?.getMiniCartProduct(section, logintoken);
        setInnerLoader(false)
      }
    });
  }

  //on click checkout check is already login or not
  const Validatelogin = (e) => {
    route.push(`/${route.query.page}/checkout/addresslist`)
  }
  //increase product qty
  const Add = async (e, cartId, is_autoship) => {
    if (+(e.target.value) >= 0 && +(e.target.value) < +(e?.target?.dataset?.value)) {
      const QUANTITY = _freeKitQuantityCheck(+(e.target.value), cartdata?.products, cartId);
      const data_update = e?.target?.name.split(',')
      const datas = {
        product_id: +(data_update[0]),
        variant_id: +(data_update[1]),
        quantity: QUANTITY,
        id: cartId,
        cookie_id: cookieCutter.get('sessionkey'),
        is_autoship: is_autoship === "True" ? true : false
      }
      if (QUANTITY)
        UpdateCartData(datas, "Add");
    }
  }
  //descrease product qty
  const Sub = (e, cartId, is_autoship) => {
    const data_update = e?.target?.name.split(',')
    const datas = {
      product_id: +(data_update[0]),
      variant_id: +(data_update[1]),
      id: cartId,
      quantity: +(e.target.value) > 0 ? (+(e.target.value) - 1) : +(e.target.value),
      cookie_id: cookieCutter.get('sessionkey'),
      is_autoship: is_autoship === "True" ? true : false
    }
    UpdateCartData(datas, "Sub");
  }

  const CUSTOM_PRODUCTS = cartdata?.products?.filter((item) => item?.product?.is_custom_product === "True") ? cartdata?.products?.filter((item) => item?.product?.is_custom_product === "True") : [];

  useEffect(() => {
    _getAllProduct().then((res) => {
      let OBJECT2 = res?.find(obj => obj?.sku === "SN-CC-CAPS-30" || obj?.sku === "SN-CC-CAPS-30-NEW");
      let OBJECT3 = res?.find(obj => obj?.sku === "SN-CC-PWDR-30" || obj?.sku === "SN-CC-PWDR-30-NEW");
      setCustomProductsData([OBJECT2, OBJECT3]);
    })
  }, [])

  function _onSelectChange(value) {
    const SPLIT_VALUE = _.split(value, '_');
    const SKU = SPLIT_VALUE[0];
    const SELECTED = _.find(customProductsData, (row) => row?.sku === SKU);
    setSelectedProduct(value);
    if (SPLIT_VALUE[1]) {
      setAutoshipCheck(true)
    } else {
      setAutoshipCheck(false)
    }
    setProduct(SELECTED)
  };

  function addProductToCart() {
    const AUTOSHIP_VALUE = ["SN-CC-CAPS-30_Subscription", "SN-CC-PWDR-30_Subscription", "SN-CC-CAPS-30-NEW_Subscription", "SN-CC-PWDR-30-NEW_Subscription"]?.includes(selectedProduct) ? "autoship" : "one-time";
    const getUserAutoShip = JSON.parse(localStorage.getItem("profileData"))?.user_data?.userdetails?.[0]?.is_autoship_user || null;

    addToCartMethod({
      id: product?.id,
      quantity: incQty,
      is_autoship: (getUserAutoShip && getUserAutoShip !== "False") || AUTOSHIP_VALUE === "one-time" ? false : true

    },
      setshowminicart,
      setCartNotify,
      cartdata?.products,
      setCartData,
      false,
      setErrorMsg,
    )
  };

  function handleChangeQty(section) {
    if (section === "sub" && incQty > 1) {
      setDecQty(prevCount => prevCount - 1);
    }
    else if (section === "add") {
      setDecQty(prevCount => prevCount + 1);
    }
  };


  return (<>
    <Head><title>Cart</title>
    </Head>
    <ViewCartStyle>
      <div className="cart-sec main_viewCart_section_class">
        <div className="container">
          <div className="cart-heading">
            <h1 className="title"> Shopping Cart  </h1>
          </div>
          <div className="table_container">
            <table className="Cart_product whistlist-table">
              {cartdata?.products &&
                <thead >
                  <tr>
                    <th>Product image</th>
                    <th>product name</th>
                    {!(props?.subscribeAlready) &&
                      <th className='pro-name'>Frequency</th>
                    }
                    <th>Quantity</th>
                    <th>Price</th>
                    <th>Action</th>
                  </tr>
                </thead>
              }
              <tbody>
                {cartdata?.message && <h3 className="title">{cartdata.message}</h3>}
                {cartdata?.products?.map((Cart, index) => {
                  return (
                    // <div key={Cart?.id}>
                    <Product is_autoship={is_autoship}
                      {...props}
                      smartShipOnCart={smartShipOnCart}
                      logintoken={logintoken}
                      cartdata={cartdata}
                      smartShipCheck={smartShipCheck}
                      key={index + 1}
                      Cart={Cart}
                      setcoupon={setcoupon}
                      deleteproduct={deleteproduct}
                      Add={Add}
                      Sub={Sub}
                    />
                    // </div>
                  )
                })}
                <SubTotalSection message={cartdata?.message} cartdata={cartdata} total={total} Validatelogin={Validatelogin} />
              </tbody>
            </table>
          </div>
          {CUSTOM_PRODUCTS?.length === 0 &&
            <RelatedProductStyle className="view-data-table">
              <RelatedProduct
                smartShipOnCart={smartShipOnCart}
                subscribeAlready={props?.subscribeAlready}
                releaseResult={releaseResult}
                section="viewCart"
                selectedProductFreq={selectedProductFreq}
                setshowminicart={setshowminicart}
                setSelectedProductFreq={(value) => setSelectedProductFreq(value)}
                relatedProduct={frequentlyProduct}
                setcoupon={setcoupon}
                cartdata={cartdata}
                route={route}
                setAmountCalculations={setAmountCalculations}
                setdiscount_amount={setdiscount_amount}
                _onSelectChange={_onSelectChange}
                handleChangeQty={handleChangeQty}
                addProductToCart={addProductToCart}
                selectedProduct={selectedProduct}
                setSelectedProduct={setSelectedProduct}
                autoshipCheck={autoshipCheck}
                setAutoshipCheck={setAutoshipCheck}
                incQty={incQty}
                setDecQty={setDecQty}
                product={product}
                setProduct={setProduct}
              />
            </RelatedProductStyle>
          }
        </div>
        {(!dnaReportData && route?.asPath !== "/us/checkout/addresslist/") &&
          <div className="col-md-12">
            <div className="charger">
              <p style={{ textAlign: 'center' }}>You will not be charged for your {product?.name} until your DNA data has been processed.</p>
            </div>
          </div>
        }
      </div>
    </ViewCartStyle>

    <SvgAnimationBackground />
  </>);
}

